/* eslint-disable no-useless-escape */
// noinspection RegExpRedundantEscape

/**
 * Created by Alex Shvets on 20.03.2017.
 */

export const DELETE_MAILS = {
  methods: {
    /**
     * Удаление писем.
     *
     * @param items {[{id: Number}, {id: Number}]}
     */
    mailDelete(items) {
      return new Promise((resolve) => {
        this.$http
          .post('v1/message/delete', {
            access_token: window.localStorage['access-token'],
            message_id: JSON.stringify(items),
          })
          .then(
            (response) => {
              if (response.body.status) {
                this.$store.dispatch('getCounters', {});
                this.$store.commit('addAlert', {
                  type: 'success',
                  text: this.$t('alerts.delSuccess'),
                });
                resolve(response.body.deleted);
              } else {
                this.$store.commit('addAlert', {
                  type: 'error',
                  text: this.$t('alerts.someErr'),
                });
              }
            },
            () => {
              this.$store.commit('addAlert', {
                type: 'error',
                text: this.$t('alerts.someErr'),
              });
            }
          );
      });
      // integer or array
    },
  },
};

export const ATTACHMENTS = {
  data() {
    return {
      attachments: [],
    };
  },
};

export const MARK_READ = {
  methods: {
    /**
     *  Отметить письма как прочитанные
     *
     *  @param id {[{id: Number}, {id: Number}]}
     */
    markAsRead(id) {
      // array or number
      this.$http
        .post('v1/message/mark', {
          access_token: window.localStorage['access-token'],
          message_id: JSON.stringify(id),
        })
        .then(() => {
          this.$store.dispatch('getCounters', {});
        });
    },
  },
};

export const CLEAN_HTML = {
  methods: {
    /**
     * Очистка HTML кода от лищних тегов
     *
     * @param html {string}
     */
    cleanHtml(html) {
      // remove body and html tag
      html = html.replace(/<script[^>]*?>(.*)/gim, '$1');
      html = html.replace(/<\/script>/gi, '');
      html = html.replace(/<iframe[^>]*?>(.*)/gim, '$1');
      html = html.replace(/<\/iframe>/gi, '');
      html = html.replace(/<html[^>]*?>(.*)/gim, '$1');
      html = html.replace(/<\/html>/gi, '');
      html = html.replace(/<body[^>]*?>(.*)/gi, '$1');
      html = html.replace(/<\/body>/gi, '');
      // html = html.replace( /<img[^>]*?>(.*)/gim, "$1" );
      html = html.replace(/<pre[^>]*?>(.*)/gim, '$1');
      html = html.replace(/<\/pre>/gi, '');
      html = html.replace(/<ol[^>]*?>(.*)/gim, '$1');
      html = html.replace(/<\/ol>/gi, '');
      html = html.replace(/<ul[^>]*?>(.*)/gim, '$1');
      html = html.replace(/<\/ul>/gi, '');
      html = html.replace(/<li[^>]*?>(.*)/gim, '$1');
      html = html.replace(/<\/li>/gi, '');
      html = html.replace(/<a[^>]*?>(.*)/gim, '$1');
      html = html.replace(/<\/a>/gi, '');

      // remove style, meta and link tags
      // html = html.replace( /<style[^>]*?>[\s\S]*?<\/style[^>]*>/gi, '' );
      html = html.replace(/<(?:meta|link)[^>]*>\s*/gi, '');

      // remove XML elements and declarations
      html = html.replace(/<\\?\?xml[^>]*>/gi, '');

      // remove w: tags with contents.
      html = html.replace(/<w:[^>]*>[\s\S]*?<\/w:[^>]*>/gi, '');

      // remove tags with XML namespace declarations: <o:p><\/o:p>
      html = html.replace(/<o:p>\s*<\/o:p>/g, '');
      html = html.replace(/<o:p>[\s\S]*?<\/o:p>/g, '&nbsp;');
      html = html.replace(/<\/?\w+:[^>]*>/gi, '');

      // remove comments [SF BUG-1481861].
      html = html.replace(/<\!--[\s\S]*?-->/g, '');
      html = html.replace(/<\!\[[\s\S]*?\]>/g, '');

      // remove mso-xxx styles.
      html = html.replace(/\s*mso-[^:]+:[^;"']+;?/gi, '');

      // remove styles.
      html = html.replace(/<(\w[^>]*) style='([^\']*)'([^>]*)/gim, '<$1$3');
      html = html.replace(/<(\w[^>]*) style="([^\"]*)"([^>]*)/gim, '<$1$3');

      // remove margin styles.
      html = html.replace(/\s*margin: 0cm 0cm 0pt\s*;/gi, '');
      html = html.replace(/\s*margin: 0cm 0cm 0pt\s*"/gi, '"');

      html = html.replace(/\s*text-indent: 0cm\s*;/gi, '');
      html = html.replace(/\s*text-indent: 0cm\s*"/gi, '"');

      html = html.replace(/\s*text-align: [^\s;]+;?"/gi, '"');

      html = html.replace(/\s*page-break-before: [^\s;]+;?"/gi, '"');

      html = html.replace(/\s*font-variant: [^\s;]+;?"/gi, '"');

      html = html.replace(/\s*tab-stops:[^;"']*;?/gi, '');
      html = html.replace(/\s*tab-stops:[^"']*/gi, '');

      // remove font face attributes.
      html = html.replace(/\s*face="[^"']*"/gi, '');
      html = html.replace(/\s*face=[^ >]*/gi, '');

      html = html.replace(/\s*font-family:[^;"']*;?/gi, '');
      html = html.replace(/\s*font-size:[^;"']*;?/gi, '');

      // remove class attributes
      html = html.replace(/<(\w[^>]*) class=([^ |>]*)([^>]*)/gi, '<$1$3');

      // remove "display:none" attributes.
      html = html.replace(/<(\w+)[^>]*\sstyle="[^"']*display\s?:\s?none[\s \S]*?<\/\1>/gi, '');

      // remove empty styles.
      html = html.replace(/\s*style='\s*'/gi, '');
      html = html.replace(/\s*style="\s*"/gi, '');

      html = html.replace(/<span\s*[^>]*>\s*&nbsp;\s*<\/span>/gi, '&nbsp;');

      html = html.replace(/<span\s*[^>]*><\/span>/gi, '');

      // remove align attributes
      html = html.replace(/<(\w[^>]*) align=([^ |>]*)([^>]*)/gi, '<$1$3');

      // remove lang attributes
      html = html.replace(/<(\w[^>]*) lang=([^ |>]*)([^>]*)/gi, '<$1$3');

      html = html.replace(/<span([^>]*)>([\s\S]*?)<\/span>/gi, '$2');

      html = html.replace(/<font\s*>([\s\S]*?)<\/font>/gi, '$1');

      html = html.replace(/<(u|i|strike)>&nbsp;<\/\1>/gi, '&nbsp;');

      html = html.replace(/<h\d>\s*<\/h\d>/gi, '');

      // remove language attributes
      html = html.replace(/<(\w[^>]*) language=([^ |>]*)([^>]*)/gi, '<$1$3');

      // remove onmouseover and onmouseout events (from MS word comments effect)
      html = html.replace(/<(\w[^>]*) onmouseover="([^\"']*)"([^>]*)/gi, '<$1$3');
      html = html.replace(/<(\w[^>]*) onmouseout="([^\"']*)"([^>]*)/gi, '<$1$3');

      // the original <Hn> tag sent from word is something like this: <Hn style="margin-top:0px;margin-bottom:0px">
      html = html.replace(/<h(\d)([^>]*)>/gi, '<h$1>');

      // word likes to insert extra <font> tags, when using IE. (Weird).
      html = html.replace(/<(h\d)><font[^>]*>([\s\S]*?)<\/font><\/\1>/gi, '<$1>$2</$1>');
      html = html.replace(/<(h\d)><em>([\s\S]*?)<\/em><\/\1>/gi, '<$1>$2</$1>');

      // i -> em, b -> strong - doesn't match nested tags e.g <b><i>some text</i></b> - not possible in regexp
      // @see - http://stackoverflow.com/questions/1721223/php-regexp-for-nested-div-tags etc.
      html = html.replace(/<b\b[^>]*>(.*?)<\/b[^>]*>/gi, '<strong>$1</strong>');
      html = html.replace(/<i\b[^>]*>(.*?)<\/i[^>]*>/gi, '<em>$1</em>');

      // remove "bad" tags
      html = html.replace(/<\s+[^>]*>/gi, '');

      // remove empty <span>s (ie. no attributes, no reason for span in pasted text)
      // done twice for nested spans
      html = html.replace(/<span>([\s\S]*?)<\/span>/gi, '$1');
      html = html.replace(/<span>([\s\S]*?)<\/span>/gi, '$1');

      // remove empty <div>s (see span)
      html = html.replace(/<div>([\s\S]*?)<\/div>/gi, '$1');
      html = html.replace(/<div>([\s\S]*?)<\/div>/gi, '$1');

      // remove empty tags (three times, just to be sure - for nested empty tags).
      // This also removes any empty anchors
      html = html.replace(/<([^\s>]+)(\s[^>]*)?>\s*<\/\1>/g, '');
      html = html.replace(/<([^\s>]+)(\s[^>]*)?>\s*<\/\1>/g, '');
      html = html.replace(/<([^\s>]+)(\s[^>]*)?>\s*<\/\1>/g, '');

      html = html.trim();

      // Make it valid xhtml
      html = html.replace(/<br>/gi, '<br />');

      // remove <br>'s that end a paragraph here.
      html = html.replace(/<br[^>]*><\/p>/gim, '</p>');

      // remove empty paragraphs - with just a &nbsp; (or whitespace) in (and tags again for good measure)
      html = html.replace(/<p>&nbsp;<\/p>/gi, '');
      html = html.replace(/<p>\s<\/p>/gi, '');
      html = html.replace(/<([^\s>]+)(\s[^>]*)?>\s*<\/\1>/g, '');

      html = html.trim();

      return html;
    },
  },
};
