<template>
  <transition v-if="open" name="modal">
    <div class="vue-modal modal-mask photos-modal">
      <div class="vue-modal--wrapper">
        <div v-click-away="closeModal" class="vue-modal--container photos-modal--body">
          <div class="photos-modal--header d-flex flex-wrap">
            <h4 class="modal-title">
              {{ $t('shared.common.allPhotos') }}
            </h4>
            <div class="set-flex">
              <rb-button class="btn-red" type="button" @click.native="passPhotos">
                <span class="rbi rbi-clip" />
                <span>{{ $t('mail.utils.attach') }}</span>
              </rb-button>
              <button type="button" class="close" @click="closeModal">
                <span class="rbi rbi-close" />
              </button>
            </div>
          </div>
          <div class="photos-modal-body">
            <div v-show="showErrLimit" class="alert alert-danger">
              {{ $t('actionPages.errors.limitReached') }}
            </div>
            <div v-if="showPreloader" class="loader-wrap">
              <div class="loader center-block" />
            </div>

            <div v-else class="photos">
              <div
                v-for="photo in photos"
                :key="photo.photo_id"
                class="photos--item photos-modal--photo"
                :class="{ checked: photo.selected }"
                @click="selectPhoto(photo)"
              >
                <div class="img-wrap">
                  <div v-background="{ img: photo._photo, imgClass: 'obj-fit' }">
                    <img
                      :src="photo._photo"
                      class="obj-fit"
                      :alt="userName + $t('shared.alts.photo')"
                    />
                  </div>
                  <div class="checkbox-emit" :class="{ checked: photo.selected }">
                    <span class="rbi rbi-check" />
                  </div>
                </div>
              </div>
            </div>

            <div v-if="!photos.length && !showPreloader" class="no-photo">
              <h2 v-if="sex.isWoman">В приватном альбоме нет фото</h2>
              <h2 v-else>You have no photo</h2>
            </div>
            <div v-show="showLimit < count && !showPreloader" class="button-wrap">
              <load-more :counter="defaultLimit" @click.native="loadMore" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { PHOTO_SRC } from '../../../mixins/utils';
import loadMore from '../../Authorized/parts/LoadMore.vue';
import RbButton from '../../Button';

export default {
  components: {
    loadMore,
    RbButton,
  },
  mixins: [PHOTO_SRC],
  props: {
    userId: Number,
    limit: Number,
    open: Boolean,
    passedCount: Number,
  },
  data() {
    return {
      showPreloader: true,
      storedPhotos: [],
      showLimit: 30,
      defaultLimit: 30,
      showErrLimit: false,
      count: 0,
    };
  },
  computed: {
    /**
     * выбрать все выбранные (отмеченные для выборки) фотографии
     *
     * @return {Array}
     */
    selectedPhotos() {
      return this.storedPhotos.filter((i) => i.selected);
    },
    /**
     * выбрать ограниченное кол-во для показа фотографии
     *
     * @return {Array}
     */
    photos() {
      return this.storedPhotos.slice(0, this.showLimit);
    },
    /**
     * получить своё имя
     *
     * @return {string}
     */
    userName() {
      return this.$store.getters.user.name;
    },
    sex() {
      return this.$store.getters.sex;
    },
  },
  watch: {
    /**
     * в зависимости от состояния модалки (открыта/закрыта) загружает фотки или очищает состояние  соответственнл
     *
     * @param val {boolean}
     */
    open(val) {
      val ? this.loadPhotos() : this.clearData();
    },
  },
  methods: {
    /**
     * очистить состояние компонента
     *
     */
    clearData() {
      this.storedPhotos = [];
      this.count = 0;
      this.showLimit = this.defaultLimit;
      this.showErrLimit = false;
    },
    /**
     * показать больше фотографий
     */
    loadMore() {
      this.showLimit += this.defaultLimit;
    },
    formatPhotos(arr) {
      return arr.map((item) => ({
        _photo: item.img_preview,
        selected: false,
        id: item.photo_id,
        loading: true, // for preloader in attachbox
      }));
    },
    loadAllPhotos() {
      this.$http
        .post('v1/photo/my-photo', {
          access_token: window.localStorage['access-token'],
          //					limit: 0,
          //					offset: 0
        })
        .then(
          (response) => {
            if (response.body.status) {
              this.count = response.body.count;
              this.storedPhotos = this.formatPhotos(response.body.result);
            } else {
              this.$store.commit('addAlert', {
                type: 'error',
                text: this.$t('alerts.requestError'),
                info: 'load photos',
              });
            }
            this.showPreloader = false;
          },
          (error) => {
            this.$store.commit('addAlert', {
              type: 'error',
              text: this.$t('alerts.requestError'),
              info: error,
            });
          }
        );
    },
    loadPrivatePhotos() {
      this.$http
        .post('v1/photo/my-album-photo', {
          access_token: window.localStorage['access-token'],
          album_type: 'private',
          with_moderation: 'accept',
        })
        .then((response) => {
          if (response.body.status) {
            this.count = response.body.count;
            this.storedPhotos = this.formatPhotos(response.body.result);
          } else {
            this.$store.commit('addAlert', {
              type: 'error',
              text: this.$t('alerts.requestError'),
              info: 'load photos',
            });
          }
          this.showPreloader = false;
        });
    },
    /**
     * загрузить фотографии пользователя
     */
    loadPhotos() {
      this.showPreloader = true;
      this.sex.isWoman ? this.loadPrivatePhotos() : this.loadAllPhotos();
    },
    /**
     * передать выбранные фотографии в родителя и закрыть модалку
     */
    passPhotos() {
      this.$emit('selected', this.selectedPhotos);
      this.closeModal();
    },
    /**
     * закрыть модалку
     */
    closeModal() {
      this.$emit('close');
      this.selectedPhotos.forEach((i) => (i.selected = false));
      this.showPreloader = true;
    },
    /**
     * выбрать фотографию
     *
     * @param photo {object}
     */
    selectPhoto(photo) {
      if (this.selectedPhotos.length + this.passedCount < this.limit) {
        photo.selected = !photo.selected;
      } else if (photo.selected) {
        photo.selected = false;
      } else {
        this.showErrLimit = true;
        photo.selected = false;
        return;
      }
      this.showErrLimit = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/mixins';
@import '../../../assets/scss/variables';
@import '../../../assets/scss/vars';

.no-photo {
  display: flex;
  align-content: center;
  justify-content: center;
  padding: 30px;
}

.btn-red {
  width: auto;
  padding: 10px 30px !important;
  font-size: 12px;
}

.loader-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
}

.button-wrap {
  padding: 30px 0;
  margin-top: 25px;
}
.checkbox-emit {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  border-radius: 2px;
  line-height: 0;
  cursor: pointer;
  transition: 0.1s ease;
  opacity: 0;
  font-size: 25px;
  color: $white;

  &.checked {
    background: rgba($red, 0.5);
    opacity: 1;
  }

  &--icon {
    width: 15px;
    height: 15px;
    opacity: 0;
    fill: #fff;
    transition: opacity 0.1s ease;
  }
}

.photos-modal {
  .alert {
    margin-top: 10px;
  }

  &--header {
    align-items: center;
    justify-content: space-between;

    svg {
      height: 16px;
      width: 16px;
      fill: #b8b8b9;
    }

    .btn {
      .rbi {
        font-size: 18px;
      }
      span {
        &:last-child {
          margin-left: 0.5em;
        }
      }
    }

    .btn + .close {
      margin-left: 25px;
    }

    .btn-primary {
      line-height: 1;
      font-size: 13px;
      padding: 10px 20px;
    }
  }

  &--body {
    width: 920px;
    padding: 25px;

    .photos {
      display: flex;
      margin: 0 -3px;
      flex-wrap: wrap;

      &--item {
        position: relative;
        margin-top: 20px;
        cursor: pointer;
        user-select: none;
        width: 25%;
        padding: 0 3px;

        .img-wrap {
          position: relative;
          padding-bottom: 88%;
          &:after {
            position: absolute;
            bottom: -3px;
            width: 25%;
            height: 3px;
            left: 0;
            content: '';
            display: block;
            background: $red;
          }
          .obj-fit {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            object-fit: cover;
          }
        }

        .checkbox-emit {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
        }

        img {
          width: 100%;
        }
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .photos-modal {
    &--body {
      width: 100%;

      .photos {
        @include customGrid(100%, 4, 24%, photos--item);
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .photos-modal {
    .modal-title {
      font-size: 18px;
    }
    &--body {
      padding: 15px;

      .photos {
        @include customGrid(100%, 3, 32%, photos--item);
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .photos-modal--header .btn + .close {
    margin-left: 10px;
  }
  .photos-modal {
    .btn-red {
      padding: 10px !important;
      width: 45px !important;
      height: 45px !important;
      .rbi {
        font-size: 16px;
      }
      span {
        &:last-child {
          display: none;
        }
      }
    }
  }
}
</style>
