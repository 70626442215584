<template>
  <div class="attach-box">
    <div class="attach-box--inner d-flex">
      <form v-if="sex.isMan" ref="upload-form">
        <input
          :id="uploaderID"
          type="file"
          class="hidden"
          accept="image/*"
          name="image"
          :disabled="uploading"
          @change="getPhoto($event)"
        />
        <div v-show="photos.length < attachLimit" class="dropdown">
          <rb-button
            :id="dropdownID"
            type="button"
            :disabled="uploading"
            @click.native.stop="toggleManDropDown()"
          >
            <span v-if="!uploading" class="d-flex align-items-center">
              <span class="rbi rbi-clip" />
              <span>{{ $t('mail.utils.attach') }}</span>
            </span>
            <template v-else>
              <i class="fa fa-refresh fa-spin fa-fw" />
              {{ $t('profile.uploading') }}
            </template>
          </rb-button>
          <div class="aselect" :data-value="manDropDownVisible">
            <div :class="{ hidden: !manDropDownVisible, manDropDownVisible }">
              <ul>
                <li @click.prevent.stop="modalOpen = true">{{ $t('mail.utils.frExist') }}</li>
                <li>
                  <label style="height: 100%; width: 100%" :for="uploaderID">{{
                    $t('mail.utils.frDevice')
                  }}</label>
                </li>
              </ul>
            </div>
          </div>
          <!--          <ul class="dropdown-menu" :aria-labelledby="dropdownID">
            <li>
              <a href="#" @click.prevent.stop="modalOpen = true">{{ $t('mail.utils.frExist') }}</a>
            </li>
            <li>
              <label :for="uploaderID">{{ $t('mail.utils.frDevice') }}</label>
            </li>
          </ul>-->
        </div>
      </form>
      <rb-button
        v-if="sex.isWoman"
        v-show="photos.length < attachLimit"
        type="button"
        @click.native="modalOpen = true"
      >
        <span class="d-flex align-items-center">
          <span class="rbi rbi-clip" />
          <span>{{ $t('mail.utils.attach') }}</span>
        </span>
      </rb-button>
    </div>
    <photos-modal
      :user-id="userId"
      :limit="attachLimit"
      :passed-count="photos.length"
      :open="modalOpen"
      @close="modalOpen = false"
      @selected="getSelectedPhotos($event)"
    />
  </div>
</template>

<script>
import { PHOTO_SRC, PHOTOS_UPLOAD } from '../../../mixins/utils';
import PhotosModal from '../modals/PhotosModal.vue';
import RbButton from '../../Button';

export default {
  components: {
    PhotosModal,
    RbButton,
  },
  mixins: [PHOTO_SRC, PHOTOS_UPLOAD],
  props: {
    clear: Number,
    userId: Number,
    photos: Array,
  },
  data() {
    return {
      modalOpen: false,
      attachLimit: 3,
      uploading: false,
      file: {},
      uploaderID: 0,
      dropdownID: 0,
      manDropDownVisible: false,
    };
  },
  computed: {
    userName() {
      return this.$store.getters.user.name;
    },
    sex() {
      return this.$store.getters.sex;
    },
  },
  watch: {
    /**
     * послать событие в родительский компонент об изменении в массиве
     *
     * @param val
     */
    photos(val) {
      this.$emit('attached', val);
    },
    /**
     * очистить массив с фотографиями
     */
    /*clear() {
      this.photos = [];
    },*/
  },
  beforeMount() {
    this.uploaderID = `uploader${Date.now()}`;
    this.dropdownID = `dropdown${Date.now()}`;

    window.addEventListener('click', this.resetDropDown);
  },

  beforeDestroy() {
    window.removeEventListener('click', this.resetDropDown);
  },
  methods: {
    resetDropDown() {
      if (this.manDropDownVisible) this.manDropDownVisible = false;
    },
    toggleManDropDown() {
      this.manDropDownVisible = !this.manDropDownVisible;
    },
    /**
     * получить выбранные фотографии с дочернего компонента
     *
     * @param data {array}
     */
    getSelectedPhotos(data) {
      this.photos = this.photos.concat(data);
    },
    /**
     * удалить фото из прикрепленныъ
     *
     * @param index {number}
     */
    unAttachPhoto(index) {
      this.photos.splice(index, 1);
    },
    /**
     * получение фотографии с input[type=form], её валидация и начало обработки
     *
     * @param e
     */
    getPhoto(e) {
      if (!this.uploading) {
        const file = e.target.files[0];
        if (file.type === 'image/jpeg' || file.type === 'image/png') {
          this.file = {
            _file: file,
          };
          if (this.$refs['upload-form']) {
            this.checkImage(this.file._file)
              .then((r) => {
                if (r) this.startTransformation(this.file);
                this.$refs['upload-form'].reset();
              })
              .catch((e) => {
                this.$refs['upload-form'].reset();
                this.$store.commit('addAlert', {
                  type: 'error',
                  text: e.description,
                });
              });
          }
        } else {
          this.$store.commit('addAlert', {
            type: 'error',
            text: this.$t('alerts.incorrectImgExt'),
          });
          this.$refs['upload-form'].reset();
        }
      }
    },
    /**
     * обработка загруженного файла: создание канваса, урезка качества, создания блоба
     *
     * @param file
     */
    startTransformation(file) {
      this.uploading = true;
      const img = document.createElement('img');
      const arr = [];
      const self = this;
      img.src = URL.createObjectURL(file._file);
      arr.push(img);
      img.onload = (e) => {
        this.makeCanvas(e)
          .then((r) => {
            const blob = self.reduceImage(r.canvas, self.constants.startQuality);
            self
              .sendBlob(blob, 'private')
              .then((sentData) => {
                self.uploading = false;
                self.photos.push({
                  id: sentData.photo_id,
                  _photo: sentData.img_preview,
                  loading: true,
                });
              })
              .catch((e) => {
                this.$store.commit('addAlert', {
                  type: 'error',
                  text: e.description,
                });
              });
          })
          .catch((e) => {
            this.uploading = false;
            this.$store.commit('addAlert', {
              type: 'error',
              text: e.description,
            });
          });
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/variables';

.attach-box {
  .aselect {
    position: absolute;
    width: 280px;
    margin: 15px auto;
    .selector {
      border: 1px solid gainsboro;
      background: #f8f8f8;
      position: relative;
      z-index: 1;
      .arrow {
        position: absolute;
        right: 10px;
        top: 40%;
        width: 0;
        height: 0;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-top: 10px solid #888;
        transform: rotateZ(0deg) translateY(0px);
        transition-duration: 0.3s;
        transition-timing-function: cubic-bezier(0.59, 1.39, 0.37, 1.01);
      }
      .expanded {
        transform: rotateZ(180deg) translateY(2px);
      }
      .label {
        display: block;
        padding: 12px;
        font-size: 16px;
        color: #888;
      }
    }
    ul {
      width: 100%;
      list-style-type: none;
      padding: 0;
      margin: 0;
      font-size: 16px;
      border: 1px solid gainsboro;
      position: absolute;
      z-index: 1;
      background: #fff;
    }
    li {
      cursor: pointer;
      padding: 10px;
      color: #666;
      &:hover {
        color: white;
        background: #676767;
      }
    }
    .current {
      background: #eaeaea;
    }
    .hidden {
      visibility: hidden;
    }
    .visible {
      visibility: visible;
    }
  }
}

input.hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
}

.dropdown-menu-new {
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  font-size: 14px;
  text-align: left;
  border: none;
  background-clip: padding-box;
  background: #ffffff;
  box-shadow: 0 0 34px 0 rgba(63, 66, 87, 0.3);
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important;
}
.open > .dropdown-menu {
  display: block;
}

.attach-box {
  &--inner {
    position: relative;
    justify-content: flex-end;
    align-items: center;
  }

  &--photos + &--btn {
    margin-left: 20px;
  }

  &--btn {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #aeaeb1;
    user-select: none;
    background: none;
    border: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
    svg {
      fill: $brand-primary;
      height: 16px;
      width: 16px;
    }

    .icon + span {
      margin-left: 5px;
    }
  }

  &--photos {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &__item + &__item {
      margin-left: 20px;
    }

    &__item {
      position: relative;
      user-select: none;
      flex: 0 0 80px;
      height: 80px;

      img {
        height: 100%;
        max-width: 100%;
      }

      .del-btn {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 32px;
        width: 32px;
        background-color: #fff;
        border-radius: 50%;
        transform: translate(50%, -50%);
        cursor: pointer;

        &:active {
          background: rgb(229, 229, 229);
          svg {
            fill: #6e6e6e;
          }
        }
      }

      svg {
        height: 24px;
        width: 24px;
        fill: #8d8d8d;
      }
    }
  }

  .dropdown-menu {
    left: auto;
    right: 0;
    top: auto;
    bottom: calc(100% + 10px);

    &:before {
      transform: scaleY(-1);
      top: 100%;
      bottom: auto;
    }

    label,
    a {
      font-weight: normal;
      color: #000;
      display: block;
      padding: 3px 20px;
      line-height: 1.428571429;
      white-space: nowrap;
      cursor: pointer;

      &:hover,
      &:focus {
        color: #262626;
        background-color: #f5f5f5;
      }
    }
  }
}
</style>
